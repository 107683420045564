import React from 'react'
import styles from './footer.module.css'

const logo = (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 277.47 244.87">
    <path className="cls-1"
          d="M263.1,232.61l51.7-44.1-51.7-44.2,68.9,33.1v11.1l-34.4,44.1,34.4,44.1v11.1l-68.9,33.1,51.7-44.2-51.7-44.1m-51.9-22.05,51.7,22.1-51.7,22,51.7,66.2L194,287.76V177.46l68.9-33.1-51.7,66.2"
          transform="translate(-54.53 -76.04)"/>
    <polyline className="cls-1" points="120.59 55.2 68.89 0 137.79 33.1 120.59 55.2"/>
    <polyline className="cls-1" points="69 0.5 0 66.7 0 33.6 69 0.5"/>
    <polyline className="cls-1" points="0 67.99 69 90.09 0 112.09 0 67.99"/>
    <polyline className="cls-1" points="0 112.99 69 179.29 0 146.19 0 112.99"/>
    <polyline className="cls-1" points="68.89 178.96 120.59 123.76 137.79 145.86 68.89 178.96"/>
  </svg>
);

export default () => (
  <div className={styles.footer}>
    <div className={styles.logo}>{logo}</div>
  </div>
)
