import React from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'
import styles from './header.module.css'
import Contact from "./contact"

export default ({path, contactData}) => {

  const isNavigationTitle = (title) => {
      return [
          'AUSSTELLUNGEN', 'WERKE', 'PUBLIKATIONEN', 'CURRICULUM',
          'TUSCHE-ZEICHNUNG',
          'PERFORMANCE',
          'FOTOGRAFIE',
          'VIDEO',
          'INSTALLATION',
          'OBJEKT',
          'SCHREIBMASCHINENTEXT',
      ].indexOf(title.toUpperCase()) > -1;
  };

  return (
      <div className={styles.header}>
        { path &&
        <>
          <div>
            <Link className={styles.path} to={'/home'}>Eva Büchi</Link><span> &gt; </span>
            {path.map((p) => (
                    <span className={styles.path} key={p.url + p.text}>
              { p.url &&
              <>
                <Link to={p.url}>{p.text}</Link><span> &gt; </span>
              </>
              }
                      { !p.url &&
                      <span className={isNavigationTitle(p.text) ? styles.path : styles.title}>{p.text}</span>
                      }
            </span>
                )
            )}
          </div>
        </>
        }
        { contactData &&
        <Contact data={contactData}></Contact>
        }
      </div>
  );
}
