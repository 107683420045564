import React from 'react'
import { Link } from 'gatsby'
import styles from './contact.module.css'

export default ({data}) => (
  <div className={styles.contact}>
    <div className={styles.text}><Link to="/">{data.name}</Link></div>
    <div className={styles.text}>{data.title}</div>
    <div className={styles.email}><a href={`mailto:${data.email}`}>{data.email}</a></div>
  </div>
)
