import React, {useState} from 'react'
import Header from './header'
import Footer from './footer'
import styles from './base.css'

class Template extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showNavigation: false};
  }

  render() {
    const { children, showHeader=true, showFooter=true, showAddress= false, path, contactData } = this.props

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <div className="main-container">
        {showHeader && <Header path={path} contactData={contactData}></Header>}
        <div className="content">{children}</div>
        {showFooter && <Footer></Footer>}
      </div>
    )
  }
}

export default Template
